

































.custom-control-label {
  user-select: none;
  cursor: pointer;
  &:before, &:After {
    width: 1rem;
    height: 1rem;
    top: 1px;
  }
}
